import {Component, viewChild} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle} from '@angular/material/expansion';
import {MatIcon} from '@angular/material/icon';
import {Help, helpData} from './help.data';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrl: './help.component.css',
  imports: [
    MatAccordion,
    MatButton,
    MatExpansionPanel,
    MatExpansionPanelHeader,
    MatExpansionPanelTitle,
    MatIcon,
  ],
})
export class HelpComponent {
  readonly accordion = viewChild.required(MatAccordion);
  data: Help[] = helpData;
}
