import {Component, inject, OnInit} from '@angular/core';
import {ChartConfiguration, ChartOptions, DefaultDataPoint} from 'chart.js';
import {BaseChartDirective} from 'ng2-charts';
import {formatPieTooltipItemToCurrency} from '../../../../../helper/tooltip-format';
import {Member} from '../../../../../model/member.model';
import {StammtischsService} from '../../../../../service/stammtischs.service';

type PieChartDataset = ChartConfiguration<'pie', DefaultDataPoint<'pie'>, string>['data']['datasets'];
type PieChartOptions = ChartOptions<'pie'>;

@Component({
  selector: 'app-balance-chart-deposit',
  imports: [
    BaseChartDirective,
  ],
  templateUrl: './balance-chart-deposit.component.html',
})
export class BalanceChartDepositComponent implements OnInit {
  private stammtischsService = inject(StammtischsService);

  chartDatasets: PieChartDataset | undefined = undefined;
  chartLabels: string[] = [];
  chartOptions: PieChartOptions;

  constructor() {
    this.chartOptions = this.getChartOptions();
  }

  ngOnInit(): void {
    this.stammtischsService.getMembers().subscribe(members => {
      this.createDataSeries(members);
    });
  }

  private createDataSeries(members: Member[]): void {
    const label: string[] = [];
    const percentages: number[] = [];
    members.forEach((member) => {
      if (0 < member.balance) {
        label.push(member.name);
        percentages.push(member.balance / 100);
      }
    });
    this.chartLabels = label;
    this.chartDatasets = [
      {
        label: 'Guthaben',
        data: percentages,
      },
    ];
    // this.chartData.datasets.data.length
  }

  private getChartOptions(): PieChartOptions {
    return {
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: 'Guthabenübersicht',
        },
        tooltip: {
          callbacks: {
            label: formatPieTooltipItemToCurrency,
          },
        },
      },
    };
  }
}
