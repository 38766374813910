import {NgOptimizedImage} from '@angular/common';
import {Component} from '@angular/core';
import {MatCardModule} from '@angular/material/card';

export interface Achievement {
  id: number;
  name: string;
  description: string;
  timestamp: number;
  done: boolean;
}

@Component({
  selector: 'app-achievements',
  imports: [
    MatCardModule,
    NgOptimizedImage,
  ],
  templateUrl: './achievements.component.html',
  styleUrl: './achievements.component.scss',
})
export class AchievementsComponent {
  achievements: Achievement[] = [
    {
      id: 1,
      name: 'bier',
      description: 'jaaa',
      timestamp: 23423,
      done: true,
    },
  ];
}
