import {CurrencyPipe} from '@angular/common';
import {Component, inject} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Drink} from '../../../../model/drink.model';
import {StammtischsService} from '../../../../service/stammtischs.service';

@Component({
  selector: 'app-drink-booking-dialog',
  imports: [
    CurrencyPipe,
    MatButton,
    MatDialogActions,
    MatDialogClose,
    MatDialogContent,
    MatDialogTitle,
  ],
  templateUrl: './drink-booking-dialog.component.html',
})
export class DrinkBookingDialogComponent {
  dialogRef = inject<MatDialogRef<DrinkBookingDialogComponent>>(MatDialogRef);
  drink = inject<Drink>(MAT_DIALOG_DATA);
  private stammtischsService = inject(StammtischsService);
  private snackbar = inject(MatSnackBar);

  loading: boolean = false;
  stammtischId: number = 27;

  constructor() {
    this.stammtischId = this.stammtischsService.selectedStammtischID;
  }

  onNoClick(): void {
    this.loading = true;
    // console.warn('buchen');
    this.stammtischsService
      .createDrunkByStammtisch(this.stammtischId, this.drink.id)
      .then(() => {
        // console.warn('buchen ok');
        this.loading = false;
        this.snackbar.open('Buchung erfolgreich');
        this.dialogRef.close(true);
      })
      .catch(() => {
        // console.warn('buchen error');
        this.loading = false;
        this.snackbar.open('Buchungsfehler');
        this.dialogRef.close(false);
      });
  }
}
