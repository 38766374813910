<app-effect-confetti></app-effect-confetti>

<app-app-navigation-side></app-app-navigation-side>
<!-- Contains sidenav and topbar. Technically router must be placed here. -->
<!-- TODO rename. -->
<!--<hr>-->
<!--<div class="mat-app-background">-->
<!--  <router-outlet></router-outlet>-->
<!--</div>-->
<!--<hr>-->
