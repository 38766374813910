import {Component, OnDestroy, OnInit} from '@angular/core';
import {Confetti, ConfettiOptions} from '@neoconfetti/vanilla';

@Component({
  selector: 'app-effect-confetti',
  templateUrl: './effect-confetti.component.html',
})
export class EffectConfettiComponent implements OnInit, OnDestroy {
  confetti: Confetti | null = null;

  public launchConfetti(): void {
    this.confetti?.explode().then(() => console.log('confetti')).catch(() => console.warn('error confetti'));
  }

  ngOnInit(): void {
    const el = document.querySelector('#confetti');
    if (el) {
      const option: ConfettiOptions = {
        stageHeight: window.innerHeight - 10,
        stageWidth: window.innerWidth - 10,
      };
      this.confetti = new Confetti(el as HTMLElement, option);
      this.confetti.explode().then(() => console.log('confetti')).catch(() => console.warn('error confetti'));
    }
  }

  ngOnDestroy(): void {
    if (this.confetti) {
      this.confetti.explode().then(() => console.log('confetti')).catch(() => console.warn('error confetti'));
    }
  }
}
