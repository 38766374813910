import {Component} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  imports: [
    MatButton,
    RouterLink,
  ],
})
export class ErrorComponent {
}
