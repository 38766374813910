/* eslint-disable  @typescript-eslint/no-explicit-any */
import {FormControl, FormGroup, Validators} from '@angular/forms';


export class FormValidators {
  static readonly REGEX_EMAIL = '^[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*' +
    '@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$';

  static readonly REGEX_TEL = '^\\+?[0-9\-() ]*$';

  static readonly ZAEHLERNUMMER_FORMAT = '(([A-Z]|[a-z])[0-9]{1,5}$)|(1(esy|emh|ESY|EMH)[0-9]{10}$)';

  // mindestens ein Zeichen das kein Space ist - egal, was davor oder danach ist
  static readonly REGEX_REQUIRED_TRIM = '.*[^\\s]+.*';

  // deutsche PLZ 5 Zahlen whitespace davor und dahinter erlaubt
  static readonly REGEX_PLZ_DE = '\\s*\\d{5}\\s*';

  static readonly MAX_FILE_SIZE: number = 5242879;

  static readonly equalsAsField = (otherFieldName: string) => {
    return (control: FormControl): Record<string, any> => {
      if (control.root instanceof FormGroup) {
        const otherControl = control.root.get(otherFieldName);
        if (otherControl != null &&
          otherControl.value != control.value) {
          return {
            mustBeEqual: true,
          };
        }
      }
      return {};
    };
  };

  /**
   * Die FormControl, der dieser Validator zugeordnet wird, ist required, wenn
   * die durch other spezifizierte FormControl den Wert ifValue annimmt.
   *
   * @param other Name der anderen FormControl
   * @param ifValue Wert, den die andere FormControl annehmen soll
   */
  static readonly requiredIfOtherValueEquals = (other: string, ifValue: any) => {
    return (control: FormControl): Record<string, any> => {
      if (control.root instanceof FormGroup) {
        const otherControl = control.root.get(other);
        if (otherControl != null && otherControl.value == ifValue &&
          (control.value === null || control.value === '' || control.value === false)) {
          return {
            required: true,
          };
        }
      }
      return {};
    };
  };

  /**
   * Die FormControl, der dieser Validator zugeordnet wird, ist required, wenn
   * die durch other spezifizierte FormControl einen der Werte annimmt, die in ifValues enthalten sind.
   *
   * @param other Name der anderen FormControl
   * @param ifValues Liste von Werten, den die andere FormControl annehmen soll
   */
  static readonly requiredIfOtherValueContainedIn = (other: string | FormControl, ifValues: any[]) => {
    if (typeof other === 'string') {
      return (control: FormControl): Record<string, any> => {
        if (control.root instanceof FormGroup) {
          let otherControl = control.root.get(other);
          // wenn auf root-Ebene nichts gefunden wird, schau relativ zu der component, die validiert wird
          if (!otherControl) {
            otherControl = control.parent?.get(other) || null;
          }
          if (otherControl != null && ifValues.includes(otherControl.value) &&
            (control.value === null || control.value === '')) {
            return {
              required: true,
            };
          }
        }
        return {};
      };
    } else {
      return (control: FormControl): Record<string, any> => {
        if (control.root instanceof FormGroup) {
          const otherControl = other;
          if (otherControl != null && ifValues.includes(otherControl.value) &&
            (control.value === null || control.value === '')) {
            return {
              required: true,
            };
          }
        }
        return {};
      };
    }

  };

  /**
   * Die FormControl, der dieser Validator zugeordnet wird, ist required, wenn
   * die durch other spezifizierte FormControl den Wert true annimmt.
   *
   * @param other Name der anderen FormControl
   */
  static readonly requiredIfOtherValueTrue = (other: string) => {
    return (control: FormControl): Record<string, any> => {
      if (control.root instanceof FormGroup) {
        const otherControl = control.root.get(other);
        if (otherControl != null && otherControl.value == true &&
          (control.value === null || control.value === '')) {
          return {
            required: true,
          };
        }
      }
      return {};
    };
  };

  /**
   * Die FormControl, der dieser Validator zugeordnet wird, ist required, wenn
   * die durch other spezifizierte FormControl einen Wert (nicht null/undefined)
   * annimmt, der UNGLEICH ifNotValue ist.
   *
   * @param other Name der anderen FormControl
   * @param ifNotValue Wert, den die andere FormControl nicht annehmen darf
   */
  static readonly requiredIfOtherValueNotEquals = (other: string, ifNotValue: any) => {
    return (control: FormControl): Record<string, any> => {
      if (control.root instanceof FormGroup) {
        const otherControl = control.root.get(other);
        if (otherControl?.value && otherControl.value != ifNotValue &&
          (control.value === null || control.value === '')) {
          return {
            required: true,
          };
        }
      }
      return {};
    };
  };

  /**
   * Die FormControl, der dieser Validator zugeordnet wird, ist required, wenn
   * die durch other spezifizierte FormControl einen Wert (nicht null/undefined)
   * annimmt, der nicht in ifNotValues enthalten sind.
   *
   * @param other Name der anderen FormControl
   * @param ifNotValues Liste von Werten, den die andere FormControl nicht annehmen darf
   */
  static readonly requiredIfOtherValueNotContainedIn = (other: string | FormControl, ifNotValues: any[]) => {
    if (typeof other === 'string') {
      return (control: FormControl): Record<string, any> => {
        if (control.root instanceof FormGroup) {
          let otherControl = control.root.get(other);
          // wenn auf root-Ebene nichts gefunden wird, schau relativ zu der component, die validiert wird
          if (!otherControl) {
            otherControl = control.parent?.get(other) || null;
          }
          if (otherControl?.value && !ifNotValues.includes(otherControl.value) &&
            (control.value === null || control.value === '')) {
            return {
              required: true,
            };
          }
        }
        return {};
      };
    } else {
      return (control: FormControl): Record<string, any> => {
        if (control.root instanceof FormGroup) {
          const otherControl = other;
          if (otherControl?.value && !ifNotValues.includes(otherControl.value) &&
            (control.value === null || control.value === '')) {
            return {
              required: true,
            };
          }
        }
        return {};
      };
    }
  };

  /**
   * Die FormControl, der dieser Validator zugeordnet wird, ist required, wenn
   * die durch other spezifizierte FormControl nicht "leer" ist.
   * Dabei werden null, undefined, die Zahl 0 und der String '' als leer betrachtet!
   *
   * @param other
   */
  static readonly requiredIfOtherNotEmpty = (other: string) => {
    return (control: FormControl): Record<string, any> => {
      if (control.root instanceof FormGroup) {
        const otherControl = control.root.get(other);
        if (otherControl?.value &&
          (control.value === null || control.value === '')) {
          return {
            required: true,
          };
        }
      }
      return {};
    };
  };

  static readonly fileMaxSize = (maxSize: number) => {
    return (control: FormControl): Record<string, any> => {
      if (control.value && control.value instanceof File &&
        control.value.size > maxSize) {
        return {
          fileMaxSize: true,
        };
      }
      return {};
    };
  };

  /*Funktion um Patterspezifische Validierungsfehler ausgeben zu können.
  pattern: ein REGEX auf das geprüft werden soll
  patternName: name des Errors der erzeugt wird wenn Validierung fehlschlägt
  Mit den Pattern Namen können dann verschiedene Hilfetexte eingeblendet werden.
  */
  static readonly namedPattern = (pattern: string | RegExp, patternName: string) => {

    if (!patternName) {
      patternName = 'pattern';
    }

    // start copy from Validators.pattern
    if (!pattern) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      return Validators.nullValidator;
    }
    let regex: RegExp;
    let regexStr;
    if (typeof pattern === 'string') {
      regexStr = '';
      if (!pattern.startsWith('^')) {
        regexStr += '^';
      }
      regexStr += pattern;
      if (!pattern.endsWith('$')) {
        regexStr += '$';
      }
      regex = new RegExp(regexStr);
    } else {
      regexStr = pattern.toString();
      regex = pattern;
    }
    // end copy from Validators.pattern

    return (control: FormControl): Record<string, any> | null => {
      if (!control.value || !regex.test(control.value)) {
        const error = {
          [patternName]: true,
        };
        return error;
      }
      return null;
    };
  };

  static readonly requiredTrimmed = () => {
    return FormValidators.namedPattern(FormValidators.REGEX_REQUIRED_TRIM, 'required');
  };

}
