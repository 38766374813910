import {Component, inject, ViewEncapsulation} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatIcon} from '@angular/material/icon';
import {MatTab, MatTabGroup, MatTabLabel} from '@angular/material/tabs';
import {NavigationEnd, Router, RouterLink} from '@angular/router';
import {filter} from 'rxjs';
import {RoutingPath} from '../../routing/routing-path.const';
import {AppShellService} from '../../service/app-shell.service';

@Component({
  selector: 'app-quick-nav',
  templateUrl: './quick-nav.component.html',
  styleUrl: './quick-nav.component.css',
  encapsulation: ViewEncapsulation.None,
  imports: [
    MatTabGroup,
    MatTab,
    MatTabLabel,
    RouterLink,
    MatIcon,
  ],
})
export class QuickNavComponent {
  appShellService = inject(AppShellService);

  RoutingPath = RoutingPath;
  selected = new FormControl(0);

  constructor() {
    const router = inject(Router);

    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.urlAfterRedirects.endsWith('drinks')) {
          this.selected.setValue(0);
        }
        if (event.urlAfterRedirects.endsWith('history')) {
          this.selected.setValue(1);
        }
        if (event.urlAfterRedirects.endsWith('payments')) {
          this.selected.setValue(2);
        }
        if (event.urlAfterRedirects.endsWith('members')) {
          this.selected.setValue(3); // fixme does not disable selection indicator.
        }
        // console.log(event);
      });
  }
}
