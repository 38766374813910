import {Component, inject, OnInit} from '@angular/core';
import {NavigationStart, Router} from '@angular/router';
import {filter} from 'rxjs';
import {AppNavigationSideComponent} from './app-navigation-side/app-navigation-side.component';
import {EffectConfettiComponent} from './elements/effect-confetti/effect-confetti.component';
import {AppShellService} from './service/app-shell.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [AppNavigationSideComponent, EffectConfettiComponent],
})
export class AppComponent implements OnInit {
  private router = inject(Router);
  private appShellService = inject(AppShellService);

  title: string = 'stammtischApp3';

  ngOnInit() {
    // this.router.events.subscribe(value => console.log(value));
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
    ).subscribe(() => {
      // console.log('NavigationEnd: ', event);
      this.appShellService.reset(); // reset if navigation
      // Your logic here. This block will run every time the route changes.
    });
  }
}
