import {CdkFixedSizeVirtualScroll} from '@angular/cdk/scrolling';
import {AsyncPipe} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import axios from 'axios';
import {BehaviorSubject} from 'rxjs';

@Component({
  selector: 'app-legal-angular-licenses',
  templateUrl: './legal-angular-licenses.component.html',
  styleUrl: './legal-angular-licenses.component.css',
  imports: [
    AsyncPipe,
    CdkFixedSizeVirtualScroll,
    MatIcon,
  ],
})
export class LegalAngularLicensesComponent implements OnInit {
  readonly loading: string = 'Lade Daten...';
  readonly error: string = 'Fehler beim Laden';
  readonly url: string = './3rdpartylicenses.txt';

  readonly licenses = new BehaviorSubject<string>(this.loading);

  constructor() {
  }

  ngOnInit(): void {
    const axiosInstance = axios.create({baseURL: '', responseType: 'text'});
    axiosInstance.get<string>(this.url)
      .then((body) => {
        this.licenses.next(body.data);
      })
      .catch(() => this.licenses.next(this.error));
  }
}
