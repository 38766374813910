import {Component, inject, OnInit} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {MatListModule, MatNavList} from '@angular/material/list';
import {RouterLink} from '@angular/router';
import {Stammtisch} from '../model/stammtisch.model';
import {RoutingPath} from '../routing/routing-path.const';
import {AppShellService} from '../service/app-shell.service';
import {StammtischsService} from '../service/stammtischs.service';

@Component({
  selector: 'app-stammtischs',
  templateUrl: './stammtischs.component.html',
  imports: [
    MatIcon,
    MatListModule,
    MatNavList,
    RouterLink,
  ],
})
export class StammtischsComponent implements OnInit {
  RoutingPath = RoutingPath;

  stammtischs: Stammtisch[] = [];

  constructor() {
    const stammtischService = inject(StammtischsService);
    const appShellService = inject(AppShellService);

    stammtischService.getStammtischs().subscribe(value => {
      this.stammtischs = value;
    });
    appShellService.setStammtisch();
  }

  ngOnInit(): void {
  }
}
