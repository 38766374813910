import {Component, inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from '@angular/forms';
import {MatButton} from '@angular/material/button';
import {MatFormField, MatLabel} from '@angular/material/form-field';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {LoginService} from '../login/login.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrl: './set-password.component.css',
  imports: [
    MatLabel,
    MatFormField,
    ReactiveFormsModule,
    MatButton,
  ],
})
export class SetPasswordComponent implements OnInit {
  private activatedRoute = inject(ActivatedRoute);
  private loginService = inject(LoginService);

  passwordGroup: FormGroup;
  success: boolean = false;
  error: string = '';

  constructor() {
    const formBuilder = inject(FormBuilder);

    this.passwordGroup = formBuilder.group({
      // eslint-disable-next-line @typescript-eslint/unbound-method
      password: ['', [Validators.required, Validators.maxLength(30), Validators.minLength(8)]],
      // eslint-disable-next-line @typescript-eslint/unbound-method
      passwordRetry: ['', [Validators.required, Validators.maxLength(30), Validators.minLength(8)]],
      // eslint-disable-next-line @typescript-eslint/unbound-method
      token: ['', [Validators.required, Validators.minLength(10)]],
    });

    this.activatedRoute.parent?.queryParamMap.subscribe((paramMap: ParamMap) => {
      const token = paramMap.get('token');
      this.passwordGroup.get('token')?.setValue(token);
    });
  }

  ngOnInit(): void {
  }

  setPassword(): void {
    this.loginService
      .setPassword(this.passwordGroup.get('password')?.value || '', this.passwordGroup.get('token')?.value || '')
      .then(() => this.success = true)
      .catch(() => this.error = 'Token ist ungültig oder abgelaufen!');
  }
}
