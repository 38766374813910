import {inject, Injectable} from '@angular/core';
import {LoginService} from '../noauth/login/login.service';

@Injectable(
  // {providedIn: 'root'}
)
export class AppInitService {
  loginService = inject(LoginService);

  init(): Promise<void> {
    // Create a promise that rejects in <ms> milliseconds
    const timeout = new Promise((resolve, reject) => {
      const ms: number = 2000;
      const id = window.setTimeout(() => {
        window.clearTimeout(id);
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        reject('App init timed out in ' + ms.toString() + 'ms.');
      }, ms);
    });

    return new Promise<void>((resolve) => { // Return resolve otherwise angular stuck in init.
        Promise.race([ // Race between dependencies and timeout.
          Promise.all([ // Require all dependencies.
            this.loginService.loadFromStorage(),
          ]),
          timeout,
        ]).then((v) => console.log(v))
          .catch((v) => console.error(v))
          .finally(() => {
            resolve();
          });
      },
    );
  }
}

export function initializeApp1(appInitService: AppInitService) {
  return (): Promise<void> => {
    return appInitService.init();
  };
}
