import {CurrencyPipe, DatePipe} from '@angular/common';
import {Component, inject, OnInit} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {MatIcon} from '@angular/material/icon';
import {RouterLink} from '@angular/router';
import {Stammtisch} from '../../model/stammtisch.model';
import {RoutingPath} from '../../routing/routing-path.const';
import {StammtischsService} from '../../service/stammtischs.service';

@Component({
  selector: 'app-stammtisch',
  templateUrl: './stammtisch.component.html',
  imports: [
    MatButton,
    RouterLink,
    MatIcon,
    CurrencyPipe,
    DatePipe,
  ],
})
export class StammtischComponent implements OnInit {
  private stammtischsService = inject(StammtischsService);

  RoutingPath = RoutingPath;

  stammtisch?: Stammtisch;
  saldo: number = 0;

  ngOnInit(): void {
    this.stammtischsService.getStammtisch().subscribe((stammtisch) => {
      this.stammtisch = stammtisch;
    });
    this.stammtischsService.getMember().subscribe((member) => {
      if (member === undefined) {
        return;
      }
      this.saldo = member.balance;
    });
  }

}
