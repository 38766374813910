import {Component, inject} from '@angular/core';
import {MatIcon} from '@angular/material/icon';
import {NgxTimelineEvent, NgxTimelineEventGroup, NgxTimelineItemPosition, NgxTimelineModule} from '@frxjs/ngx-timeline';
import {BehaviorSubject} from 'rxjs';
import {Drunk} from '../../../model/drunk.model';
import {Payment} from '../../../model/payment.model';
import {StammtischsService} from '../../../service/stammtischs.service';

@Component({
  selector: 'app-stammtisch-history',
  imports: [
    NgxTimelineModule,
    MatIcon,
  ],
  templateUrl: './stammtisch-history.component.html',
  styleUrl: './stammtisch-history.component.css',
})
export class StammtischHistoryComponent {
  private stammtischsService = inject(StammtischsService);

  public events: NgxTimelineEvent[] = [];

  private drunks: Drunk[] = [];
  private payments: Payment[] = [];
  private loading: BehaviorSubject<number>;
  protected readonly NgxTimelineEventGroup = NgxTimelineEventGroup;

  constructor() {
    this.loading = new BehaviorSubject<number>(2);
    this.stammtischsService.getDrunks().subscribe((data) => {
      this.drunks = data;
      this.loading.next(this.loading.value - 1);
    });
    this.stammtischsService.getPayments().subscribe((data) => {
      this.payments = data;
      this.loading.next(this.loading.value - 1);
    });
    this.loading.subscribe((value) => {
      if (value <= 0) {
        this.createHistory();
      }
    });
  }

  private createHistory(): void {
    this.events = [];
    this.drunks.forEach(
      (drunk) => {
        const event: NgxTimelineEvent = {
          title: 'Getrunken',
          description: drunk.drink?.name,
          // description: drunk.id_drink.toString(),
          timestamp: new Date(drunk.time * 1000),
          itemPosition: NgxTimelineItemPosition.ON_RIGHT,
        };
        this.events.push(event);
      },
    );
    this.payments.forEach(
      (payment) => {
        const event: NgxTimelineEvent = {
          title: 'Gezahlt',
          description: (payment.price / 100).toString() + '€',
          timestamp: new Date(payment.time * 1000),
          itemPosition: NgxTimelineItemPosition.ON_LEFT,
        };
        this.events.push(event);
      },
    );
  }
}
