import {CurrencyPipe} from '@angular/common';
import {AfterViewInit, Component, inject, TemplateRef, viewChild} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatDivider} from '@angular/material/divider';
import {MatIcon} from '@angular/material/icon';
import {MatMenuItem} from '@angular/material/menu';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatCell, MatColumnDef, MatHeaderCell, MatHeaderRow, MatTableDataSource, MatTableModule} from '@angular/material/table';
import {CardWarningComponent} from '../../../elements/card-warning/card-warning.component';
import {DialogConfirmComponent, DialogConfirmData} from '../../../elements/dialog-confirm/dialog-confirm.component';
import {Member} from '../../../model/member.model';
import {RoutingPath} from '../../../routing/routing-path.const';
import {AppShellService} from '../../../service/app-shell.service';
import {StammtischsService} from '../../../service/stammtischs.service';
import {MembersBalanceChartsComponent} from './members-balance-charts/members-balance-charts.component';

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrl: './members.component.css',
  imports: [
    CardWarningComponent,
    CurrencyPipe,
    MatCell,
    MatColumnDef,
    MatDivider,
    MatHeaderCell,
    MatHeaderRow,
    MatIcon,
    MatMenuItem,
    MatPaginatorModule,
    MatSortModule,
    MatTableModule,
    MembersBalanceChartsComponent,
  ],
})
export class MembersComponent implements AfterViewInit {
  private stammtischsService = inject(StammtischsService);
  private appShellService = inject(AppShellService);
  private dialog = inject(MatDialog);
  private snackbar = inject(MatSnackBar);

  protected readonly RoutingPath = RoutingPath;

  dataSource = new MatTableDataSource<Member>([]);
  // displayedColumns: string[] = ['id_user', 'name', 'sumDebts', 'sumPayments', 'balance'];
  displayedColumns: string[] = ['id_user', 'name', 'balance'];
  pageSizeOptions: number[] = [5, 10, 50];

  readonly menuMore = viewChild.required<TemplateRef<void>>('menu_more');
  readonly paginator = viewChild.required(MatPaginator);
  readonly sort = viewChild.required(MatSort);

  constructor() {
    this.stammtischsService.getMembers().subscribe(
      (members) => {
        this.dataSource.data = [];
        this.dataSource.data.push(...members);
      });
  }

  ngAfterViewInit(): void {
    this.appShellService.moreMenu.next(this.menuMore());
    this.dataSource.paginator = this.paginator();
    this.dataSource.sort = this.sort();
  }

  doNotifyMembersOpening(): void {
    const data: DialogConfirmData = {
      text: 'Benachrichtige alle Mitglieder über Stammtischeröffnung?',
      title: 'Stammtischeröffnung?',
    };
    this.dialog
      .open(
        DialogConfirmComponent,
        {data: data},
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.stammtischsService.doNotifyMembersOpening()
            .then(() => this.snackbar.open('Stammtischeröffnung erfolgreich.'))
            .catch(() => this.snackbar.open('Stammtischeröffnung fehlgeschlagen.'))
          ;
        }
      });
  }

  doNotifyMembersClosing(): void {
    const data: DialogConfirmData = {
      text: 'Benachrichtige alle Mitglieder über Stammtischende?',
      title: 'Ende Gelände?',
    };
    this.dialog
      .open(
        DialogConfirmComponent,
        {data: data},
      )
      .afterClosed()
      .subscribe((result) => {
        if (result === true) {
          this.stammtischsService.doNotifyMembersClosing()
            .then(() => this.snackbar.open('Stammtischende erfolgreich.'))
            .catch(() => this.snackbar.open('Stammtischende fehlgeschlagen.'))
          ;
        }
      });
  }
}
