import {Component} from '@angular/core';
import {MatButton} from '@angular/material/button';
import {RouterLink} from '@angular/router';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  imports: [
    RouterLink,
    MatButton,
  ],
})
export class ForbiddenComponent {
}
