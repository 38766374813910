import {BreakpointObserver, BreakpointState} from '@angular/cdk/layout';
import {DOCUMENT} from '@angular/common';
import {inject, Injectable} from '@angular/core';
import {StorageMap} from '@ngx-pwa/local-storage';
import {BehaviorSubject, distinctUntilChanged} from 'rxjs';

/* TODO: store setting. */

// export enum Themes {
export enum Themes {
  LIGHT = 'theme_light',
  DARK = 'theme_dark',
}

export type ThemesKey = keyof typeof Themes;

@Injectable({
  providedIn: 'root',
})
export class ThemeSwitchService {
  private document = inject<Document>(DOCUMENT);
  private breakpointObserver = inject(BreakpointObserver);
  private storage = inject(StorageMap);

  readonly defaultTheme: Themes = Themes.DARK;
  currentThemeSubject: BehaviorSubject<Themes> = new BehaviorSubject<Themes>(this.defaultTheme);
  readonly storageKeyTheme: string = 'currentTheme';

  /**
   * Note: Renderer2 does not work in services.
   **/
  constructor() {
    this.currentTheme$.subscribe((v) => console.log(v));
    this.currentTheme$.subscribe(current => this.updateTheme(current));
    this.storage.get(this.storageKeyTheme).subscribe((theme) => {
      theme = theme as Themes;
      if (theme == Themes.LIGHT) {
        this.setLight();
      } else {
        this.setDark();
      }
    });
    this.currentTheme$.subscribe(current => {
      this.storage.set(this.storageKeyTheme, current.toString()).subscribe();
    });
  }

  private autodetectTheme(): void {
    // Add auto-detection. Just auto-select if explicit preference given.
    this.breakpointObserver
      .observe(['(prefers-color-scheme: light)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          console.log('Light theme autodetect.');
          this.setLight();
        }
      });
    this.breakpointObserver
      .observe(['(prefers-color-scheme: dark)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          console.log('Dark theme autodetect.');
          this.setDark();
        }
      });
  }

  private removeAllThemes(classElement: HTMLElement): void {
    for (const key in Themes) {
      const theme: string = Themes[key as ThemesKey].toString();
      classElement.classList.remove(theme);
    }
  }

  private updateMeta(theme: Themes): void {
    // disable other.
    for (const key in Themes) {
      const theme: string = Themes[key as ThemesKey].toString();
      this.document.getElementById(theme)?.setAttribute('media', '(not all)');
    }

    const el: HTMLElement | null = this.document.getElementById('theme_custom');
    const c: string = this.document.getElementById(theme)?.getAttribute('content') ?? '#0000ff';
    el?.setAttribute('content', c);
    el?.removeAttribute('media');
  }

  updateTheme(theme: Themes): void {
    if (this.document?.documentElement == null) {
      console.error('documentElement not initialized.');
      return;
    }
    const classElement: HTMLElement = this.document.documentElement;
    this.removeAllThemes(classElement);
    classElement.classList.add(theme.toString());
    this.updateMeta(theme);
  }

  public setDark(): void {
    this.currentThemeSubject.next(Themes.DARK);
  }

  public setLight(): void {
    this.currentThemeSubject.next(Themes.LIGHT);
  }

  public switch(): void {
    if (this.isDark) {
      this.setLight();
    } else {
      this.setDark();
    }
  }

  get isDark(): boolean {
    return this.currentThemeSubject.value == Themes.DARK;
  }

  get isLight(): boolean {
    return this.currentThemeSubject.value == Themes.LIGHT;
  }

  get currentTheme$() {
    return this.currentThemeSubject.asObservable().pipe(distinctUntilChanged());
  }
}
